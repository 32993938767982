import React from 'react';

import PageContainer from '../page-container';

import './index.scss';

export const Roadmap = () => {
  return (
    <PageContainer id='roadmap' pageTitle='Roadmap' className='roadmap'>
      <br />
      <br />
      <img width='80%' src={`${window.location.origin}/img/roadmap.png`} alt='roadmap' />
    </PageContainer>
  )
};

export default Roadmap;