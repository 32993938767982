import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import ThemedButton from '../themed-button';

import './index.scss';

interface IProps {
  imageUrl: string;
  characterName: string;
  onClick: () => void;
  disabled?: boolean;
  beastId: number;
  bg1: string;
  bg2: string;
  bg3: string;
  barColor: string;
  borderGradientColor: string;
  comingsoon?: boolean;
}

export const PbCharacterTile = ({imageUrl, characterName, onClick, disabled, beastId, bg1, bg2, bg3, comingsoon, barColor,borderGradientColor }: IProps) => {
  const [leftToMintCount, setLeftToMintCount] = useState(0);


  const getBeastsLeftToMintCount = (beastId: number) => {

    const endpoint = `${process.env.REACT_APP_API}/beasts/left-to-mint/${beastId}`;
    axios.get(endpoint)
    .then(({data: {leftToMint}}: AxiosResponse<{leftToMint: number, beastId: number}>) => {
      setLeftToMintCount(leftToMint);
    });
  }

  useEffect(() => {
    if(!disabled && characterName && !comingsoon) {
      getBeastsLeftToMintCount(beastId);
    }
  }, [beastId, disabled, characterName, comingsoon]);

  return (
    <div className='pb-character-tile' style={{background: bg1}}>
      <div className='pb-character-tile-2' style={{background: bg2}}>
        <div className='pb-character-tile-3-container' style={{background: borderGradientColor}}>
          <div className='pb-character-tile-3' style={{background: bg3}}>
            {
              disabled || comingsoon?
              <img src={`${window.location.origin}/img/question-mark.png`} style={{opacity: 1}} className='pb-character-tile-image' alt="Coming Soon" />
              :
              <img className='pb-character-tile-image' src={imageUrl} alt="planet-beast-character" />
            }
          </div>
        </div>
        <br />
        <div
          className='pb-character-tile-bar'
          style={{
            background: barColor
          }}
        />
        {
          !disabled && !comingsoon?
          <div className='pb-character-tile-label-container'>
            <p>{characterName}</p>
            {
              leftToMintCount > 0 ? <ThemedButton onClick={onClick}>Mint <small className='price'>(0.07ETH)</small></ThemedButton> : characterName ? <p>Sold out!</p> : null
            }
          </div> :
          <div className='pb-character-tile-label-container'>
            <p>{characterName}</p>
            {
              <ThemedButton onClick={onClick} >Coming Soon</ThemedButton>
            }
          </div>
        }
        {
          !disabled && !comingsoon ? <p>{750 - leftToMintCount}/750 minted</p> : <p>&nbsp;</p>
        }
      </div>
    </div>
  )
};