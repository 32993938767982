
import MetamaskMissing from '../components/metamask-missing';
export const { ethereum } = window as any;



export const getCurrentWalletConnected = async () => {
  if (ethereum) {
    try {
      const addressArray = await ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0] as string,
          status: 'ok'
        };
      } else {
        return {
          address: addressArray[0] as string,
          status: 'not connected',
          error: true,
          needToConnect: true
        };
      }
    } catch ({message}) {
      return {
        address: "",
        status: "😥 " + message,
      };
    }
  } else {
    return {
      error: true,
      missing: true,
      address: "",
      status: <MetamaskMissing />,
    };
  }
};

export const connectWallet = async () => {
  if (ethereum) {
    try {
      const addressArray = await ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log(addressArray);
      return {
        address: addressArray[0] as string,
        status: 'ok'
      };
    } catch ({message}) {
      return {
        error: true,
        address: "",
        status: "😥 " + message,
      };
    }
  } else {
    return {
      error: true,
      address: "",
      missing: true,
      status:
         <MetamaskMissing />
    };
  }
};

