import React from 'react';
import './index.scss';

interface IProps {
  label: string;
  value: string;
}

const Metric = ({label, value}: IProps) => {
  return (
    <div className='metric-container'>
      <p className='metric-value'>{value}</p>
      <p className='metric-label'>{label}</p>
    </div>
  )
};

export default Metric;