import React from 'react';

import './index.scss';

const AdvancedFooter = () => {
  return (
    <div className='advanced-footer-container'>
      <div></div>
      <img
        className='advanced-footer-logo'
        alt='planet-beast-logo'
        src={`${window.location.origin}/logo.png`} />
      <div>
        <h1>About Us</h1>
        <p>Our Mission</p>
        <p>We're Hiring!</p>
      </div>
      <div>
        <h1>Contact Us</h1>
        <p>planetbeastofficial@gmail.com</p>
      </div>
      <div></div>
    </div>
  )
};

export default AdvancedFooter;