import React from 'react';

import TwitterIcon from '@mui/icons-material/Twitter';

import './index.scss';
const Footer = () => {

  const handleTwitterRedirect = () => {
    const anchor = document.createElement('a');
    anchor.href = 'https://twitter.com/PlanetBeastGame';
    anchor.target = '_blank';
    anchor.click();
  }

  return (
    <div className='footer-container'>
      <div className='simple-footer'>
      <span>Terms & privacy</span>
      <span>© {new Date().getFullYear()} Planet beast All Rights Reserved</span>
      <span >Follow us on: <TwitterIcon onClick={handleTwitterRedirect}/></span>
      </div>
    </div>
  )
};

export default Footer;