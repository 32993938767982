
import React, { PropsWithChildren } from 'react';
import { Button, ButtonProps, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  background: 'linear-gradient(to right bottom, #5a04ec, #8d2eee 80%)'
  // '&:hover': {
  //   backgroundColor: purple[700],
  // },
}));

interface IProps extends PropsWithChildren<any> {
  onClick?: (event?: any) => void;
  className?: string;
  tooltip?: any;
  disabled?: boolean;
}

const ThemedButton = ({children, onClick, className, tooltip, disabled}: IProps) => {
  return (
    tooltip ? <Tooltip title={tooltip}>
      <ColorButton
        disabled={disabled}
        className={className}
        onClick={onClick} variant='contained'
        >
          {children}
        </ColorButton>
      </Tooltip>
      :
      <ColorButton
      className={className}
      onClick={onClick} variant='contained'>{children}</ColorButton>

  )
}

export default ThemedButton;