import React from 'react';
import PbCard from '../../components/pb-card';

import PageContainer from '../page-container';

import './index.scss';

export const About = () => {
  return (
    <PageContainer id='about' pageTitle='What are planet beasts?' className='about'>
      <p style={{padding: '0 10%', lineHeight: '2em', fontFamily: '"Roboto", sans-serif'}}>Planet Beasts are unique NFT Creatures that are connected to the forces of nature on planet Elementus. Through the use of "Essence", they are able to manipulate a certain element based on their individual affinities. Essence is the innate energy that courses through all life on planet Elementus. Planet Beast owners are able to stake their beasts and earn our utility token $ESNC that powers our ecosystem. </p>
      <div className="about-cards-container">
        <PbCard
          label="1"
          title={`Revolutionary "NFT ECONOMICS"`}
          summary="The ecosystem is revolved around the NFTs themselves. They generate value naturally through utility, scarcity, and collectability."
        />
        <PbCard
          label="2"
          title={`Unique Characters`}
          summary={`Most NFT collections have 10,000 different copies of the same character. We feel this makes each character lack originality and creates a lot of "common" characters. With a lower mint number and a greater number of characters, each one will truly be special and rare in its own way. Although, some will still be extra rare!`}
                />
        <PbCard
          label="3"
          title={`Tokenomics that ensure longevity`}
          summary={[`No arbitrary burns to force scarcity. No inflationary mechanisms that reduce token value and force longevity. Use your tokens for character purchases, in-game items, staking, and governance voting`, <span key='1' className='trigger'>!<span className='tooltip'>In the first medium article&hellip;</span></span>, ` Multiple use cases ensure that tokens continue to be used, circulated, and creates real value through utility. PLUS a portion of every transaction that occurs on Planet Beast, will go back to the rewards pool. This constant replenishing makes rewards sustainable for years to come.`]}
        />
      </div>
    </PageContainer>
  )
};

export default About;