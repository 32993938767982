import React from 'react';

import PageContainer from '../page-container';

import './index.scss';

export const Transparency = () => {
  return (
    <PageContainer id='transparency' pageTitle='Transparency' className='transparency'>
      <p>We have outlined the allocation of our funds and tokens in order to build</p>
      <p>confidence in our ecosystem</p>
      <br />
      <br />
      <div className='transparency-snapshots'>
        <div className='transparency-snapshot'>
          <img width='90%' className='transparency-snapshot-image' src={`${window.location.origin}/img/transparency/initial-sales.png`} alt='initial-sales' />
          <h1 className='transparency-snapshot-title'>Initial Sales <br /> Gen 1</h1>
          <p className='transparency-snapshot-description' >50% of the profit from Gen 1 sales will go directly to the community wallet, where the community can help decide the best allocation strategy for those funds. Community is our primary <span className='trigger'>focus<span className='tooltip'>Search for the first word of the phrase&hellip;</span></span>.</p>
          <p className='transparency-snapshot-description' >30% will go towards the development fund. This is to pay for: Developers, Marketing & Advertising, Artists, Server Fees, and everything in between</p>
          <p className='transparency-snapshot-description' >20% is for profit.</p>
        </div>
        <div className='transparency-snapshot'>
          <img width='80%' className='transparency-snapshot-image' src={`${window.location.origin}/img/transparency/token-allocation.png`} alt='initial-sales' />
          <h1 className='transparency-snapshot-title'>Token Allocation of ESNC <br /> After Gen 1</h1>
          <p className='transparency-snapshot-description' >After the sale of all Gen 1 characters, the token will launch.</p>
          <p className='transparency-snapshot-description' >35% of the supply will go to exchanges.</p>
          <p className='transparency-snapshot-description' >45% of the supply will be locked up in the rewards pool for players to earn.</p>
          <p className='transparency-snapshot-description' >5% of the tokens will be locked up for giveaways and promotions.</p>
          <p className='transparency-snapshot-description' >15% of the tokens will be kept for the development fund and profit. When we sell these tokens, it will only be at a maximum of 2% of the total funds per week as to not devalue the token’s price.</p>
        </div>
        <div className='transparency-snapshot'>
          <img width='80%' className='transparency-snapshot-image' src={`${window.location.origin}/img/transparency/character-sales.png`} alt='initial-sales' />
          <h1 className='transparency-snapshot-title'>Character Sales <br /> Gen 2 and Gen 3</h1>
          <p className='transparency-snapshot-description' >30% of each Planet Beast sale from Gen 2 & Gen 3 as well as sales of in-game items will go back into the rewards pool. This ensures that the rewards pool is always filling up and constantly replenishing as the user base grows.</p>
          <p className='transparency-snapshot-description' >70% will go to profit.</p>
        </div>
      </div>
    </PageContainer>
  )
};

export default Transparency;