import React from 'react';

import './index.scss';

interface IProps {
  label: string;
  title: string;
  summary: any;
}

const PbCard = ({label, title, summary}: IProps) => {
  return (
    <div
    className='pb-card'
    >
      <p className='pb-card-label'>{label}</p>
      <div className='pb-card-summary-container'>
        <p className='pb-card-title'>{title}</p>
        <p className='pb-card-summary'>{summary}</p>
      </div>
    </div>
  )
};

export default PbCard;