import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './App.scss';
import AdvancedFooter from './components/advanced-footer';
import Footer from './components/footer';
import MenuBar from './components/menu-bar/MenuBar';
import About from './pages/about';
import Characters from './pages/characters';
import ContactUs from './pages/contact-us';
import Faqs from './pages/faqs';
import Home from './pages/home';
import Roadmap from './pages/roadmap';
import Transparency from './pages/transparency';
import { setWalletAddress } from './redux/actions';
import { ethereum } from './util/wallet';
import { getCurrentWalletConnected } from './util/wallet';


function App() {
  const [accountChangeListenerSet, setAccountChangeListenerSet] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getCurrentWalletConnected()
      .then(res => {
        if(res?.address) {
          dispatch(setWalletAddress(res?.address));
        }
      });
    if(!accountChangeListenerSet) {
      ethereum?.on('accountsChanged', (accounts: Array<string>) => {
        setAccountChangeListenerSet(true);
        dispatch(setWalletAddress(accounts[0]));
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
      });
    }
  }, [accountChangeListenerSet, dispatch]);

  return (
    <div className='app-container'>
      <div className='root-background'>
        <div className="yellow-right-top parent-container">
        <div className="pink-right-bottom child-container">
        <div className="purple-left-top child-container">

        </div>
        </div>
        </div>

        <div className="pink-right-top parent-container">
        <div className="blue-right-bottom child-container">
        <div className="pink-left-bottom child-container">

        </div>
        </div>
        </div>

        <div className="pink-left-top parent-container">
        <div className="blue-left-bottom child-container">
        <div className="yellow-right child-container">
        <div className="blue-top-right child-container">

        </div>
        </div>
        </div>
        </div>

        <div className="yellow-left">
        <div className="blue-left-top">
        <div className="pink-right-middle">
        <div className="blue-right-middle">

        </div>
        </div>
        </div>
        </div>
      </div>

      <MenuBar />
      <Home />
      <About />
      <Roadmap />
      <Transparency />
      <Characters />
      <Faqs />
      <ContactUs />
      <AdvancedFooter />
      <Footer />
    </div>
  );
}

export default App;
