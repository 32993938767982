import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import './index.scss';

interface IProps {
  title: string;
  summary: JSX.IntrinsicElements | React.ReactNode | string;
}

const PbAccordion = ({title, summary}: IProps) => {
  return (
    <div className='pb-accordion-container'>
      <Accordion className='pb-accordion'>
        <AccordionSummary
          expandIcon={<AddIcon style={{color: 'white'}} />}
        >
          <Typography className='pb-accordion-text'>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className='pb-accordion-text'>
            {summary}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
};

export default PbAccordion;