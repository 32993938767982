import React from 'react';
import PbAccordion from '../../components/pb-accordion';
import ThemedButton from '../../components/themed-button';

import PageContainer from '../page-container';

import './index.scss';

const FAQS = [{
  faq: 'What makes us different?',
  answer: <div>
    <p>There are two main problems with NFT projects</p>
    <ol>
      <li key="1">Most NFT projects give you 10,000 cookie-cutter copy-and-paste versions of the same character. This leaves for many “common” variations to be minted
  Planet Beast limits the number of NFT’s by splitting up the collection through different characters, making each character much more unique and scarce.</li>
      <li key="2">In most play-to-earn games, only early adopters are given an opportunity to purchase most of the rare characters.
  With Planet Beast, it doesn't matter whether you are first or last. Each person will have an equal opportunity to obtain a rare NFT via “Randomized Minting”. That being said, it is always good to be an early adopter to maximize your investment through your play strategy. Plus, owners of the first 100 minted variants of each character will receive free $ESNC tokens!</li>
    </ol>
  </div>
},
{
  faq: 'How many Planet Beasts can I own?',
  answer: 'You can own up to 5 Planet Beasts. Limiting the number of Planet Beasts one person can own increases the value of each one and allows for a better community. See how in our whitepaper!'
},
{
  faq: 'How much does one cost?',
  answer: 'One planet beast costs 0.07 ETH on Polygon'
},
{
  faq: 'How does staking work?',
  answer: 'You can stake your beast right on our websites staking dashboard'
},
{
  faq: 'Why should I buy a Planet Beast?',
  answer: <div>
    <p className="marginless">You get the same benefits as you would from other projects, the right to earn our token for free. Additional benefits include metaverse access, <span className='trigger'>exclusive<span className='tooltip'>"___ of Planet Beasts"</span></span> content from partnerships, giveaways, and much more!</p>
  </div>
},
{
  faq: 'How will Planet Beast Launch?',
  answer: 'We will launch with 3 beasts to start and then release one at a time until all of them have been revealed. Staking will be first so that users can start to earn immediately before the actual game is finished being developed. Follow us on Twitter! We will announce when a new Planet Beast will be revealed well before it happens!'
},
{
  faq: 'Will the token be on exchanges?',
  answer: 'We plan to list $ESNC on as many DEX’s and CEX’s as possible for the best liquidity possible and to avoid price crashes. Most exchanges have an extremely rigorous process to get a coin listed, as well as many other coins on their list potentially ahead of ours. We anticipate it to be a slow process. However, it will be one of our top priorities'
},
{
  faq: 'How much can I earn?',
  answer: <div>
    <p>The dollar amount will fluctuate based on the market price of $ESNC. As for right now, here is the split: </p>
    <p>Generation 1 Planet Beasts earn 0.5 $ESNC daily</p>
    <p>Generation 2 Planet Beasts earn 2  $ESNC daily</p>
    <p>Generation 3 Planet Beasts earn 5 $ESNC daily</p>
    <p>If needed, governance voting can occur to adjust these amounts. However, you can maximize your earnings no matter which generation of beast you have through in-game items, special variants, and combo’s of NFT ownership. As well as through actual gameplay.</p>
  </div>
}]



export const Faqs = () => {

  const openWhitepaper = () => {
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.target = '_blank';
    anchor.href = `${window.location.origin}/whitepaper.pdf`;

    anchor.click();
  }

  return (
      <PageContainer id='faqs' pageTitle='Frequently Asked Questions' className='faqs'>
        <div className='faqs-container'>
          {
            FAQS.map((faq, index) => (
              <PbAccordion key={index} title={faq.faq} summary={faq.answer} />
            ))
          }
          <br />
          <br />
          <ThemedButton className='faqs-read-the-whitepaper-button' onClick={openWhitepaper}>Read the whitepaper</ThemedButton>
        </div>
      </PageContainer>
  )
};

export default Faqs;