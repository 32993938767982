
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWalletAddress } from '../../redux/actions';
import { IReduxState } from '../../redux/reducer';
import { connectWallet, getCurrentWalletConnected } from '../../util/wallet';
import MetamaskMissing from '../metamask-missing';
import ThemedButton from '../themed-button';

import './index.scss';

const ConnectWalletButton = () => {
  const wallet = useSelector((state: IReduxState) => state.connectedWallet);
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletError, setWalletError] = useState<any>(null);
  const [metamaskMissing, setMetamaskMissing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!walletError && !wallet) {
      getCurrentWalletConnected()
      .then(res => {
        if(res?.needToConnect) {
          setMetamaskMissing(false);
          setWalletConnected(false);
          setWalletError(false);
        } else {
          setWalletError(res?.status !== 'ok');
          setWalletConnected(res?.status !== 'not connected');
          setMetamaskMissing(res?.missing as boolean);
          dispatch(setWalletAddress(res?.address));
        }
      })
    }
  }, [walletError, wallet, dispatch]);

  const handleClick = () => {
    connectWallet()
    .then(res => {
      console.log(res);
      if(res?.error) {
        setWalletError(<MetamaskMissing />);
        setMetamaskMissing(res?.missing as boolean);
      } else {
        setWalletError(false);
        setMetamaskMissing(false);
        setWalletConnected(true);
        dispatch(setWalletAddress(res?.address));
      }
    })
  }

  return (
      !walletError ? <ThemedButton tooltip={metamaskMissing ? <MetamaskMissing /> : null} onClick={handleClick} variant='contained'>{
        walletConnected ? <span className='wallet-label'>{wallet}</span> : 'Connect Wallet'
      }</ThemedButton> : walletError
  )
}

export default ConnectWalletButton;