import { Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ConnectWalletButton from '../connect-wallet';
import MenuBarItem from '../menu-bar-item/MenuBarItem';
import ThemedButton from '../themed-button';

import './MenuBar.scss';



const MenuBar = () => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenuButton, setShowMenuButton] = useState(window.innerWidth < 1500);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      setShowMenuButton(window.innerWidth < 1500);
    })
  });

  return (
    <div className='menu'>
      <div className='menu-container' style={{background: `url(${window.location.origin}/img/menu-shadow.png)`, backgroundSize: '100% 100%'}}>
        <img className='application-logo' src={`${window.location.origin}/logo.png`} alt='planet-beast-logo' />
        <div className='menu-bar'>
          {
            showMenuButton ?
            <div>
              <ThemedButton className='menu-button' onClick={(event) => {
                setAnchorEl(event.target);
                setMenuOpen(true)
              }}>Menu</ThemedButton>
              <Menu anchorEl={anchorEl} open={menuOpen} onClose={() => setMenuOpen(false)}>
                  <MenuItem>
                    <MenuBarItem pageTargetId='#home'>Home</MenuBarItem>
                  </MenuItem>
                  <MenuItem>
                    <MenuBarItem pageTargetId='#about'>About</MenuBarItem>
                  </MenuItem>
                  <MenuItem>
                    <MenuBarItem pageTargetId='#roadmap'>How It Works</MenuBarItem>
                  </MenuItem>
                  <MenuItem>
                    <MenuBarItem pageTargetId='#transparency'>Transparency</MenuBarItem>
                  </MenuItem>
                  <MenuItem>
                    <MenuBarItem pageTargetId='#characters'>Characters</MenuBarItem>
                  </MenuItem>
                  <MenuItem>
                    <MenuBarItem pageTargetId='#faqs'>FAQs</MenuBarItem>
                  </MenuItem>
                  <MenuItem>
                    <MenuBarItem pageTargetId='#contactus'>Contact Us</MenuBarItem>
                  </MenuItem>
                  <MenuItem>
                    <ConnectWalletButton />
                  </MenuItem>
              </Menu>
              </div>
              :
              <React.Fragment>
                <MenuBarItem pageTargetId='#home'>Home</MenuBarItem>
                <MenuBarItem pageTargetId='#about'>About</MenuBarItem>
                <MenuBarItem pageTargetId='#roadmap'>How it works</MenuBarItem>
                <MenuBarItem pageTargetId='#transparency'>Transparency</MenuBarItem>
                <MenuBarItem pageTargetId='#characters'>Characters</MenuBarItem>
                <MenuBarItem pageTargetId='#faqs'>FAQs</MenuBarItem>
                <MenuBarItem pageTargetId='#contactus'>Contact us</MenuBarItem>
                <ConnectWalletButton />
              </React.Fragment>
              }

        </div>
      </div>
    </div>

  );
}

export default MenuBar;