import React from 'react'
import Metric from '../../components/metric'
import ThemedButton from '../../components/themed-button'

import PageContainer from '../page-container'

import './index.scss'

export const Home = () => {
  return (
    <PageContainer id="home" className='home'>
      <div className='home-background'>
        <img width='60%' alt="planet" src={`${window.location.origin}/img/black-hole.png`} />
        </div>
      <div className="home-container">
        {/* <p className="game-title">Planet Beast</p> */}

        <h1 className="game-tag-line">
        Planet Beast <br />
          A Play-To-Earn Game
        </h1>

        <p className="game-description">
        Planet Beast is a "P2E Adventure & PVP Game". The game revolves around 6 NFT characters, and utilizes our unique "NFT Economics" to give them both value and utility.
        <br />
        Battle, earn, explore, and become the "King of Planet Beasts"
        </p>


       <a href='#characters' style={{textDecoration: 'none'}}>
         <ThemedButton
          disabled
          className='mint-beast-button'
          >
            Mint your beast HERE!
          </ThemedButton>
        </a>

        <br />
        <br />

        <div>
          <Metric label="Beasts" value="6" />
          <Metric label="Variations" value="750" />
        </div>
      </div>
    </PageContainer>
  )
}
export default Home
