import { Alert, Snackbar } from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { PbCharacterTile } from '../../components/pb-character-tile';
import ThemedButton from '../../components/themed-button';
import { IApprovalResponse, IApprovalTransaction } from '../../models/IApprovalResponse';
import { IMintSetup } from '../../models/IMintSetup';
import { IReduxState } from '../../redux/reducer';
import { ethereum } from '../../util/wallet';
import PageContainer from '../page-container';

import './index.scss';

interface IProps {
  connectedWallet?: string;
}

interface IState {
  mintSetupRequest: IMintSetup;
  submittedTxHash: string;
  snackBarMessage: string;
  snackBarOpen: boolean;
}

class Characters extends React.Component<IProps, IState> {



  constructor(props: IProps) {
    super(props);
    this.state = {
      mintSetupRequest: {
        beastId: -1,
        beastNftContentUrl: '',
        beast_instance_id: -1
      },
      submittedTxHash: '',
      snackBarMessage: '',
      snackBarOpen: false
    };
  }

  handleMintRequest = async (id: number) => {

    if(await this.ensureUserIsApproved()) {
      const endpoint = `${process.env.REACT_APP_API}/beasts/setup-mint/${this.props.connectedWallet}/${id}`;

      axios.get(endpoint)
      .then((res: AxiosResponse<IMintSetup>) => {
        this.setState({
          mintSetupRequest: res.data
        });

        this.requestTransactionSignature();
      })
      .catch((err: AxiosError<IMintSetup>) => {
        if(err.response?.data.error) {
          this.setState({
            snackBarMessage: err.response.data.errorMessage as string,
            snackBarOpen: true
          });
        }
      });
    }

  }

  ensureUserIsApproved = (): Promise<boolean> => {
    console.log(this.props.connectedWallet);
    const endpoint = `${process.env.REACT_APP_API}/beasts/approved/${this.props.connectedWallet}`;

    return axios.get(endpoint)
    .then((res: AxiosResponse<IApprovalResponse>) => {
      console.log(res);
      if(!res.data.approved) {
        console.log(res.data);
        this.approveWallet(res.data.approvalTransaction as IApprovalTransaction);
        return false;
      }

      return true;
    }).catch((err: AxiosError<any>) => {
      console.log('error occurred when ensuring user is approved to pay for nft', err);
      return false;
    });
  }

  approveWallet = async (tx: IApprovalTransaction) => {
    console.log("Submitting transaction", tx);
    ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        tx
      ]
    })
    .then((submittedTxHash: string) => {
      console.log("submitted approval transaction");
    })
    .catch((err: any) => {
      console.log(err);
    });
  }

  requestTransactionSignature = () => {
    ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        this.state.mintSetupRequest.tx
      ]
    })
    .then((submittedTxHash: string) => {
      this.setState({
        submittedTxHash
      })
    })
    .catch((err: any) => {
      console.log(err);
      this.revertMintSetup();
    });
  }

  revertMintSetup = () => {
    const endpoint = `${process.env.REACT_APP_API}/beasts/revert-mint-setup/${this.state.mintSetupRequest.beast_instance_id}`;

    axios.put(endpoint)
    .then(res => console.log(res))
    .catch(err => console.log(err));
  }

  navigateToTransactionStatusLink = () => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href=`https://ropsten.etherscan.io/tx/${this.state.submittedTxHash}`;
    a.click();
  }

  render() {
    return  (
    <PageContainer id='characters' className='characters' pageTitle='NFT Character Gallery'>
      <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      open={this.state.snackBarOpen}
      onClose={() => this.setState({snackBarOpen: false})}
      autoHideDuration={4000}
      >
        <Alert severity='error' variant='filled' >{this.state.snackBarMessage}</Alert>
        </Snackbar>
      {
        this.state.submittedTxHash &&
        <ThemedButton
          onClick={this.navigateToTransactionStatusLink}
        >
          View Transaction
        </ThemedButton>
      }
      <div className='characters-row'>
        <PbCharacterTile
        bg1="linear-gradient(to top left, #deb01b, #ffd650)"
        bg2="radial-gradient(ellipse at top left, #e1b72e, #a58311)"
        bg3="linear-gradient(to top right, #e3d7af, #b38b0a 35%)"
        barColor='linear-gradient(#b38b0a 20%, #ffd858)'
        borderGradientColor='linear-gradient(to top, #dec881 20%, #deba45)'
        beastId={1}
        imageUrl={`${window.location.origin}/img/pharazaar.png`}
        characterName='Pharazaar'
        onClick={() => this.handleMintRequest(1)}
        />
         <PbCharacterTile
        bg1="linear-gradient(to top left, #19b53d, #3bff69)"
        bg2="radial-gradient(ellipse at top left, #30e65b, #0f9a30)"
        bg3="linear-gradient(to top right, #a7e3b5, #098025 35%)"
        barColor='linear-gradient(#098025 20%, #55ff7c)'
        borderGradientColor='linear-gradient(to top, #73ff93 20%, #15c13d)'
        beastId={2}
        imageUrl={`${window.location.origin}/img/monarking.png`}
        characterName='Monarking'
        onClick={() => this.handleMintRequest(2)}
        />
        <PbCharacterTile
        bg1="linear-gradient(to top left, #dd2c01, #ff552b)"
        bg2="radial-gradient(ellipse at top left, #d35c34, #cd4011)"
        bg3="linear-gradient(to top right, #ebb9a3, #c6450e 35%)"
        barColor='linear-gradient(#c6450e 20%, #ff7f48)'
        borderGradientColor='linear-gradient(to top, #fc8856 20%, #ed510e)'
        beastId={3}
        imageUrl={`${window.location.origin}/img/drakorr.png`}
        characterName='Drakorr'
        onClick={() => this.handleMintRequest(3)}
        />

      </div>
      <div className='characters-row'>

        <PbCharacterTile
        bg1="linear-gradient(to top left, #6dede8, #9dfffc)"
        bg2="radial-gradient(ellipse at top left, #79e9e6, #3dd7d2)"
        bg3="linear-gradient(to top right, #b3dfde, #0fa7a2 35%)"
        barColor='linear-gradient(#0fa7a2 20%, #4cf5f0)'
        borderGradientColor='linear-gradient(to top, #56f5f0 20%, #19d2cb)'
        beastId={4}
        imageUrl={`${window.location.origin}/img/niveus.png`}
        characterName='Niveus'
        onClick={() => this.handleMintRequest(4)}
        />
         <PbCharacterTile
        bg1="linear-gradient(to top left, #b6bbb4, #ededed)"
        bg2="radial-gradient(ellipse at top left, #cdcdcd, #a0a49e)"
        bg3="linear-gradient(to top right, #e1e1e1, #858584 35%)"
        barColor='linear-gradient(#858584 20%, #b5b5af)'
        borderGradientColor='linear-gradient(to top, #b6bbb4 20%, #aeaeae)'
        beastId={5}
        imageUrl={`${window.location.origin}/img/aerodyce.png`}
        characterName='Aerodyce'
        onClick={() => this.handleMintRequest(5)}
        />
        <PbCharacterTile
        bg1="linear-gradient(to top left, #2346ed, #2b4eff)"
        bg2="radial-gradient(ellipse at top left, #7389f5, #3148bb)"
        bg3="linear-gradient(to top right, #d8dcf2, #4864ee 35%)"
        barColor='linear-gradient(#2747e4 20%, #7389f5)'
        borderGradientColor='linear-gradient(to top, #7389f5 20%, #1b3eed)'
        beastId={6}
        imageUrl={`${window.location.origin}/img/wartide.png`}
        characterName='Wartide'
        onClick={() => this.handleMintRequest(6)}
        />
      </div>
    </PageContainer>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  connectedWallet: state.connectedWallet
})

export default connect(mapStateToProps, null)(Characters);