import React from 'react';

import PageContainer from '../page-container';
import ThemedButton from '../../components/themed-button';
import { TextField, TextFieldProps} from '@mui/material';
import { styled } from '@mui/material/styles';

import './index.scss';

const StyledInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  color: 'white',
  // background: 'linear-gradient(to right bottom, #5a04ec, #8d2eee 80%)'
  // '&:hover': {
  //   backgroundColor: purple[700],
  // },
}));

export const ContactUs = () => {
  return (
    <PageContainer id='contactus' className='contactus'>
      <div className='contactus-container' style={{background: `url(${window.location.origin}/img/contactus.png)`, backgroundSize:'100% 100%', backgroundRepeat: 'no-repeat'}}>
        <h1 className='contactus-title'>Planet Beast</h1>
        <h4 className='contactus-subtitle'>Let's Talk</h4>
        <div>
          <StyledInput className='contactus-form-item' variant='outlined' placeholder='Email'
          />
          <ThemedButton className='contactus-form-item' onClick={() => {}}>Submit</ThemedButton>
        </div>
      </div>
    </PageContainer>
  )
};

export default ContactUs;