import React, { PropsWithChildren } from 'react';

import './MenuBarItem.scss';

interface IProps extends PropsWithChildren<any> {
  pageTargetId: string
}

const MenuBarItem = ({children, pageTargetId}: IProps) => {
  return (
    <a href={pageTargetId} className='menu-bar-item'>
      {children}
    </a>
  )
}

export default MenuBarItem