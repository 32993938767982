import { SET_WALLET_ADDRESS } from "./actions";

export interface IReduxState {
  connectedWallet: string;
};

export interface IReduxAction {
  type: string,
  payload: any
};

const initialState: IReduxState = {
  connectedWallet: ''
};

const reducer = (state: IReduxState = initialState, action: IReduxAction): IReduxState => {
  switch(action.type) {
    case SET_WALLET_ADDRESS:
      return {
        ...state,
        connectedWallet: action.payload as string
      }
    default:
      return state;
  }
}

export default reducer;