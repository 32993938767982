import React, { PropsWithChildren } from 'react';

import './index.scss';

interface IProps extends PropsWithChildren<any> {
  id: string;
  pageTitle?: string;
  className?: string;
}

const PageContainer = ({children, id, pageTitle, className}: IProps) => (
  <div className={`page-container ${className}`} id={id}>
    {
      pageTitle &&
      <h1 className='page-container-title'>{pageTitle}</h1>
    }
    {children}
  </div>
)

export default PageContainer;