import React from 'react';

const MetamaskMissing = () => {
  return <p style={{color: '#fff'}}>
  {" "}
  🦊{" "}
  <a rel="noreferrer" target="_blank" href="https://metamask.io/download.html">
    Install Metamask
  </a>
</p>
};

export default MetamaskMissing;